@import '../../css/definition.colors.scss';

.LinkEmailPage {
	display: flex;
	flex-direction: column;
}

.personalInfo {
	text-align: start;
	margin-bottom: 35px;
	color: $blisss-darkblue;
}

.fullName {
	margin-bottom: 16px;

	.text {
		margin-left: 18px;
	}
}

.emailAddress {
	.text {
		margin-left: 16px;
	}
}

.content {
	.header {
		text-transform: uppercase;
	}

	h1,
	p {
		margin: 5px;
	}

	margin-bottom: 35px;
}

.personalInfo,
.content {
	font-family: fntRoboto;
	font-size: 24px;
	font-variation-settings: "wdth" 65, "wght" 450;
}

.icon {}