// @import '../../../components/Grid/grid.module';
@import '../../../css/definition.colors.scss';

.agendaView {
	box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	background-color: white;
	margin: 43px 5px 0;
	max-height: calc(100% - 65px);
	display: flex;
	flex-direction: column;
	flex: 60px;
}

.scheduler {
	flex: 1;

	box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
	max-height: calc(100vh - 210px);
	border-radius: 0 0 8px 8px;
}

.schedulerHeader {
	position: relative;
	font-variation-settings: "wdth" 65, "wght" 450;

	.rowSubHeader {
		padding: 23px 57.5px 23px 20px;
		height: 35px;
		box-sizing: content-box;
		border-radius: 8px 8px 0 0;
		border: none;
		display: flex;
		justify-content: space-between;
		color: $blisss-darkblue;
		font-size: 24px;
		text-transform: capitalize;
		box-shadow: none;

		.icon {
			width: 30px;
			margin-right: 10px;
			margin-bottom: 5px;
		}
	}

	.tabs {
		flex: 0 0 40px;
		position: absolute;
		display: flex;
		top: -33px;
		font-variation-settings: "wdth" 65, "wght" 450;
		text-transform: uppercase;
		padding: 0 12px;
		font-size: 16px;
	}

	.tab {
		background-color: transparent;
		padding: 8px 15px 5px;
		margin: 0;
		border: solid transparent;
		border-width: 2px 2px 0;

		&.active {
			border-color: $grey-block-border;
			border-radius: 8px 8px 0 0;
			background-color: $white;
		}
	}
}

.agendaItem {
	background-color: $blisss-blue;
}

.contentItem {
	overflow: hidden;
	text-overflow: ellipsis;
}

.icon.right {
	position: absolute;
	right: 0;
	top: -33px;

}


.title {
	display: flex;
	line-height: 35px;

	.listIcon {
		width: 30px;
		margin-right: 10px;
		margin-bottom: 5px;
	}
}

.dateRange {
	text-align: center;
	font-size: 17px;
	line-height: 35px;
	color: $blisss-darkblue;
	text-transform: uppercase;
}

.dateButtons {
	text-align: end;

	button {
		margin: 0 4px;
	}
}