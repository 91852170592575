@import '../../css/definition.colors.scss';

.container {
  > div {
    border: none !important;
    > div {
      color: $blisss-blue !important;
      font-weight: normal !important;
      font-variation-settings: normal !important;
    }
    > input {
      margin-right: 5px !important;
    }
    svg {
      color: $blisss-blue !important;
    }
  }
}