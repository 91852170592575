@import '../../css/definition.colors.scss';
.container {
    flex: 1 1 auto;
	margin-left: 10px;
	margin-right: 10px;
	display: flex;
	align-items: center;
    justify-content: center;
	border-radius: 15px;
	background-color: $grey-accent-background;
	height: 35px;
}