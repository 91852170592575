@import '../../css/definition.colors.scss';

.inputField {
	width: 100%;
	padding: 10px 0;
	text-align: center;
	background-color: $grey-accent-background;
	border-color: transparent;
	border-radius: 4px;
	color: $gray5;

	&.error {
		border-radius: 4px;
		border: solid $red 1px;
	}

	&:focus {
		outline-color: transparent;
		border: 2px solid $light-blue;
		color: $black;
	}
}

.passwordResetLink {
	color: $blisss-orange;
	display: block;
	margin-top: 10px;
	text-align: center;
	width: 100%;
	font-size: 15px;
	text-transform: lowercase;
	

	&:hover {
		color: $blisss-darkblue;
	}
}

.label {
	display: block;
	text-align: center;
	margin: 0;
	margin-bottom: 10px;
}

h5.label {
	text-transform: uppercase;
	font-size: 15px;
}

.textDanger {
	color: $blisss-red;
	font-size: 12px;
}