@import './definition.dimensions.scss';
@import './definition.colors.scss';

.main {
	display: flex;
	height: 100%;
	min-height: 0;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	width: 100%;
	max-width: $general-page-width;
	justify-content: center;
}

.footer {
	height: 10px;
	flex-shrink: 0;
}

.footer_area {
	height: 100%;
	max-width: $general-page-width;
	margin: 0 auto;
	overflow: auto;
}

.marginAuto {
	margin: auto;
}

.drawerHeader {
	text-align: center;

	.drawerHeaderText {
		margin: 8px auto;
		display: inline-block;
	}

	.closeButton {
		float: right;
	}
}

.menuList {
	width: 250px;
}

.backgroundColor {
	background-color: $gray4;
	height: 100vh;
	width: 100%;
	overflow-x: scroll;
}

.mainContent {
	max-width: $general-page-width;
	margin: auto;
	padding-top: 64px;
	display: flex;
	flex-flow: column;

	.container {
		display: flex;
		min-height: 100%;

		.columnTitle {
			text-transform: uppercase;
		}

		.column {
			flex: 1;
			margin: 10px;
			min-height: 100%;

			header {
				display: flex;

				.item {
					flex: 1;
				}
			}

			&.double {
				flex: 2;
			}

			.columnShadow {
				background-color: $white;
				min-height: 100%;
				overflow: hidden;
				margin: 0 auto;
				border: 1px solid #ccc;
				border-radius: 5px;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
			}
		}
	}
}

// Header

header {
	min-width: 100%;
	height: 63px;
	flex-shrink: 0;
	background-image: linear-gradient(to right, #1B3A6D, #095495);
	border-bottom: 1px solid #1B3A6D;
	box-shadow: 0px 4.5px 10px rgba(0, 0, 0, 0.15);
}

.headerArea {
	height: 100%;
	max-width: $general-page-width;
	margin: 0 auto;
}

.headerNavigation {
	display: flex;

	/* NOT min-height. 100% height makes the "buttons" easier to click */
	height: 100%;

	align-items: center;
	/* align vertical */

	.spinnerContainer {
		position: absolute;
		margin-left: 2px;
	}
}

.headerLogo {
	display: flex;
	flex: 0 0 150px;
	height: 100%;
	padding-left: 2px;

	@media (max-width: 1100px) {
		transition: 0.3s;
		display: none;
	}
}

.headerLogo img {
	position: absolute;
	margin-top: 18px;
	height: 30px;
}

.headerMenu {
	flex: 1;
	letter-spacing: 0.5px;
	font-variation-settings: "wdth" 65, "wght" 450;
	text-transform: uppercase;
	height: 100%;
	overflow: hidden;

	.sep {
		float: left;
		display: flex;
		height: 100%;
		align-items: center;
		color: white;
		padding-left: 2px;
		padding-right: 2px;

		@media (max-width: 1130px) {
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	a {
		color: white;
		color: $blisss-blue;
		padding-left: 15px;
		padding-right: 15px;

		&:link,
		&:visited {
			display: flex;
			align-items: center;
			height: 100%;
			float: left;
			font-size: 20px;
			text-decoration: none;
			transition: 0.3s;

			@media (max-width: 860px) {
				font-size: 15px;
			}
		}

		&:hover {
			color: #FFF;
			color: #F68B1F;
		}

		@media (max-width: 950px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	@media (max-width: 930px) {
		letter-spacing: 0.3px;
		font-variation-settings: "wdth" 50, "wght" 300;
		font-size: 15px;
	}

}

.headerMenuActive {
	color: #FFF !important;
	color: $blisss-orange !important;
}

.headerSettings {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;

	.linkFill,
	.linkFillActive {
		position: relative;
		font-size: 25px;
		height: 100%;
	}

	.impersonation {
		color: $blisss-orange;
		text-align: center;
		text-decoration: underline;
		font-size: 20px;
		letter-spacing: 0px;
		text-transform: uppercase;
		opacity: 1;
		padding: 0 20px;
	}

	.linkFillActive {
		color: $blisss-orange !important;
	}

	.icon,
	i {
		padding-left: 10px;
		padding-right: 10px;
		transition: 0.3s;
	}

	a {
		font-size: 25px;
		height: 100%;
		display: flex;
		align-items: center;

		&:link,
		&:visited {
			text-decoration: none;
			color: $blisss-blue;
		}

		&:hover {
			color: $blisss-darkblue;
			color: $blisss-orange;
		}
	}

	.profile {
		display: flex;
		align-items: center;
		text-decoration: none;
		justify-content: center;
		font-size: 22px;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		background-color: #27aae1;
		color: #fff;
		margin-left: 10px;
		border: none;
		transition: 1s;

		&:hover {
			background-color: #1e3768;
			/* FOR FUN: background: linear-gradient(to bottom, orange , yellow, green, cyan, blue, violet); */
			cursor: pointer;
		}
	}

	.bell,
	.help,
	.tools,
	.fullscreen {
		position: relative;
		font-size: 25px;
		height: 100%;
	}

	.bell .notifications {
		right: 0;
		top: 0;
		position: absolute;
		text-align: center;
		margin-top: 8px;
		padding-top: 2px;
		height: 18px;
		width: 20px;
		border-radius: 50%;
		font-size: 14px;
		background-color: #bb342f;
		color: #ffffffee;
		pointer-events: none;
	}
}