@import '../../../css/definition.colors.scss';
.subtitle {
    color: $blisss-darkblue;
    font-variation-settings: 'wght' 500;
    text-transform: uppercase;
    padding: 5px 20px;
    border-bottom: 1px solid $grey-outline-border;
}
.selectedEntitiesWrapper {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px); 
    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .treeView {
            padding: 10px 20px;
            input {
                &:checked {
                    border-color: $blisss-blue;
                    background-color: $blisss-blue;
                    &:focus {
                        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
                    }
                }
            }
            ul {
                li {
                    span {
                        box-shadow: none;
                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }
        .action {
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid $grey-outline-border;
            padding: 10px 20px;
            button {
                svg {
                    right: 25px;
                    bottom: 5.5px;
                }
            }
        }
    }
}
.entitiesWrapper {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $gray4;
    .sideEntity {
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        padding: 10px;
        cursor: pointer;
        border-bottom: solid $grey-outline-border 1px;

        &.activeEntity {
            background-color: $blue-hover;
            color: $blisss-darkblue;
            font-variation-settings: 'wght' 500;
        }
        &:hover {
            background-color: $blue-hover;
        }
    }
 
}
.noDataAvailable {
	display: block;
	text-align: center;
	margin: 20px;
	font-style: italic;
	font-variation-settings: 'GRAD' 100;
}
.dialogContent {
    padding: 16px;
}