@import '../../css/definition.colors.scss';
.checkboxContainer {
    display: flex;
    align-items: center;
    width: 100%;
    p {
        font-size: 14px;
        margin: 0;
        color: $grey-search-magnifier;
        text-transform: capitalize;
        font-variation-settings: 'wght' 350, 'wdth' 50;
    }
    .checkbox {
        display: flex;
        align-items: center; 
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        cursor: pointer;
        &.checked {
            p {
                color: $blisss-blue;
            }
        }
    }
    .checkboxWrapper {
        display: block;
        position: relative;
        cursor: pointer;
        height: 14px;
        width: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked ~ .check {
            background-color: $blisss-blue;
            border: 1px solid $blisss-blue;
        }

        input:checked ~ .check:after {
            display: block;
        }

        .check {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background: $white;
            border: 1px solid $gray4;
            box-sizing: border-box;
            border-radius: 2px;
        }

        .check:after {
            content: '';
            position: absolute;
            display: none;
            left: 4.5px;
            top: 1px;
            width: 3px;
            height: 8px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}