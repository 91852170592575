@import '../../../css/definition.colors.scss';

.popupFinishAppointment {
	max-width: 1200px;
	height: 700px;
	display: flex;
	max-height: calc(100vh - 64px);
}

.appointmentFinishPopup {
	width: 1080px;
	height: 680px;
	max-height: calc(100% - 20px);
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	height: 100px;
	width: 100%;
}

.headerText {
	width: 100%;
	text-align: center;
	color: $blisss-darkblue;
	font-style: italic;
	font-variation-settings: 'GRAD' 100;
	height: 20px;
}

.content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.footer {
	align-self: flex-end;
	display: flex;
	flex-direction: row;
	height: 40px;
	width: 100%;
	margin-bottom: 10px;
}

.borders {
	padding-top: 25px;
	width: 100%;
}

.tab {
	position: relative;
	flex-direction: column;
	// background-color: red;
	z-index: 1;
	flex: 1;
	align-self: flex-start;
}

.entityListView {
	height: 480px;
	max-height: calc(100vh - 300px);
	margin-bottom: 16px;
}

.appointmentListView {
	@extend .entityListView;
	height: 420px;
	max-height: calc(100vh - 360px);
}

.annotationListView {
	height: 455px !important;
	max-height: calc(100vh - 300px);
	margin-bottom: 16px;
}

.progressBar {
	position: absolute;
	top: 36.5px;

}