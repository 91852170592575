@import './css/definition.colors.scss';
@import './css/definition.dimensions.scss';
@import './localization/capitalization.scss';
@import '~@progress/kendo-theme-default/dist/all.scss';

// This file should be used (mainly) by public/index.html
// And very specific components
// Please use general.module.scss for regular shared css
* {
	font-family: fntRoboto;
}

html,
body {
	font-size: 16px;
	/* todo: 16px should be like 13px */

	width: 100%;
	height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	background-color: $grey-page;
}

body {
	display: flex;
	flex-direction: column;
	background-image: url('./images/bg1.webp');
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: auto;
}

#root {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: 100vh;
}

code {
	color: #E01A76;
}

a {
	color: $blisss-linkblue;
}

a i {
	color: #27aae1;

	&:hover {
		color: #1e3768;
	}
}

.modal-container {
	position: relative;
	z-index: 500;
}

.customDialog {
	background-color: rgba(0, 0, 0, 0.25);
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.status_indicator {
	width: 12px;
	height: 12px;
	display: inline-block;
	border-radius: 100%;
	margin-right: 5px;

	&.green {
		background-color: $blisss-green;
	}

	&.blue {
		background-color: $blisss-blue;
	}

	&.orange {
		background-color: $blisss-orange;
	}

	&.red {
		background-color: $blisss-red;
	}

	&.grey {
		background-color: $gray5;
	}

	&.yellow {
		background-color: $yellow;
	}
}

/****************************************************/


// #region MEDIA QUERIES
@media only screen and (min-width: 1921px) {

	//TEMP: Joas override
	body {
		background-image: none;
	}
}

// #endregion

// #region COLORS (leave at bottom)	
.clr_b_white {
	color: #FFF;
}

.clr_f_white {
	background-color: #FFF !important;
}

.clr_f_transparant {
	color: transparent;
}

.clr_b_transparant {
	background-color: transparent !important;
}

.clr_f_green {
	color: $blisss-green;
}

.clr_f_red {
	color: $blisss-red;
}

.clr_f_orange {
	color: $blisss-orange;
}

.clr_f_blue {
	color: #27aae1;
}

.clr_f_darkblue {
	color: $blisss-darkblue;
}

.clr_f_grey-border {
	color: $grey-block-border;
}

.clr_b_green {
	background-color: $blisss-green !important;
}

.clr_b_red {
	background-color: $blisss-red !important;
}

.clr_b_orange {
	background-color: $blisss-orange !important;
}

.clr_b_blue {
	background-color: $blisss-blue !important;
}

.clr_b_gray {
	background-color: $gray5 !important;
}

.clr_b_darkblue {
	background-color: $blisss-darkblue !important;
}

.clr_b_selected {
	background-color: $blue-hover !important;
}

.clr_b_grey-border {
	background-color: $grey-block-border !important;
}

// #endregion

// Kendo react dialog style
.k-dialog-wrapper {
    .k-overlay {
        background-color: rgba(0, 0, 0, 0.25);
    }
    .k-dialog {
        min-height: 180px;
        max-height: 500px;
        width: 500px;
        background-color: $grey-page;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 5px;
        box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.25);

        .k-window-titlebar {
            border-radius:5px 5px 0  0;
            border: none; 
            background-color: $grey-page;
            
            .k-dialog-title{
                font-size: 17px;
                color: $black;
                font-weight: 600;
            }
        }
         p {
            font-size: 16px;
         }
        .k-actions {
            border-top: solid rgba(0, 0, 0, 0.25) 1px;
            display: flex;
            justify-content: flex-end;
            padding: 13px 16px;
            button {
                flex: none;
            }
        }
    }
}

// Kendo react multiselect dropdown
.multiselect-container {
   &.option-selected {
    .k-multiselect {
        background-color: $blue-selected;
    }
   } 
}
.k-multiselect {
    border-radius: 0;
    padding: 3.5px;
    border: 1px solid $gray4;

    &.k-focus {
        box-shadow: none;
        &:after {
            transform: rotate(180deg);
        }
    }

    .k-chip-solid-base {
        border: none;
        color: $blisss-blue;
        font-variation-settings: 'wght' 450, 'wdth' 50;
        background-color: transparent;
        background-image: none;
    }
    &:after {
        content: "\25be";
        position: absolute;
        top: 15%;
        right: 8px;
        font-size: 18px;
    }
    .k-clear-value {
        display: none;
    }
    input {
        &::placeholder {
            text-transform: capitalize;
        }
    }
    .k-chip-content{
       span {
        text-transform: capitalize;
       } 
    }

}
.k-popup {
    border: 1px solid $gray4;
    border-top: none;
    ul {
        &.k-list-ul {
            li {
                &.k-list-item {
                    background-color: $white;
                    box-shadow: none;
                    padding: 10px;
                }
            }
        }
    }
}