//Testing, quick development colors
$white: #FFF;
$transparent-white: rgba(255, 255, 255, 0.75);

$gray1: #F5F5F5;
$gray2: #EEE;
$gray3: #E0E0E0;
$gray4: #BDBDBD;
$gray5: #9E9E9E;
$gray6: #757575;
$gray7: #616161;
$gray8: #424242;
$gray9: #212121;

$black: #000;

$red: #D50000;
$dark-red: #B71C1C;
$light-blue: #0288D1;
$indigo: #3F51B5;
$dark-indigo: #1A237E;
$yellow: #dbfa0e;

//Corporate Identity
$blisss-blue: #27AAE1;
$blisss-linkblue: #0366d6;
$blisss-darkblue: #1E3768;
$blisss-orange: #F68B1F;
$blisss-green: #21A179;
$blisss-red: #BB342F;

//Selection
$blue-selected: #DDF4FF; //Regular selected element color
$blue-hover: #EBF8FF; //Regular hover element color
$red-hover: #FFEBEB;

//Other colors
$grey-page: #E7E7E7; //Page background
$grey-block-border: #D5D7DB; //Regular border color
$grey-outline-border: #CCCCCC; //Outline border color for columns and such
$grey-accent-background: #EFF1F2; //For example: Search
$grey-search-magnifier: #707070; //Simply, the grey color of search component\
$red-page: #fff1f1;
$red-outline-border: #e7aaaa;