.SSOButton {
	font: 15px 'Segoe UI';
	font-weight: 600;
	color: white;
	background-color: #2f2f2f;

	height: 41px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	padding: 0 12px;
	box-sizing: border-box;
	transition: color .1s, background-color .1s;

	&.loading {
		cursor: wait;
	}

	&:hover {
		color: #5e5e5e;
		background-color: white;
		border: solid 1px #8c8c8c;

		padding: 0 11px;
	}
}

.msLogo {
	margin-right: 12px;
}