@import '../../css/definition.colors.scss';

.alert {
	background-color: rgb(253, 236, 234);
	display: flex;
	padding: 6px 16px;
	font-size: 0.875rem;
    align-items: center;

	.icon {
		display: flex;
		opacity: 0.9;
		padding: 7px 0;
		font-size: 22px;
		margin-right: 12px;
		color: $blisss-red;
	}

	.message {
		color: rgb(97, 26, 21);
	}
}

.box {
	margin-top: 100px;
	margin-left: auto;
	margin-right: auto;
	border-color: $black;
	padding: 0 16px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	justify-content: center;

	@media (min-width: 600px) {
		padding: 0 24px;
	}

	@media (min-width: 1280px) {
		max-width: 1280px;
	}
}

.centerBox {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	padding: 35px 0;
	background-color: $white;
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	justify-content: center;
	text-align: center;

	@media screen and (max-width: 576px) {
		height: calc(100% - 60px);
		border-radius: 0;
	}
}

.centerBoxContent {
	flex-grow: 0;
	max-width: 83.333333%;
	flex-basis: 83.333333%;
}

.salesFlowLogo {
	width: 50%;
	height: 50%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.boxHeader {
	background-color: $transparent-white;
	padding: 15px 0;
	border-radius: 25px 25px 0 0;
	filter: blur(0px);
	-webkit-filter: blur(0px);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	margin-left: auto;
	margin-right: auto;
}

.infoIcon {
	background-color: $gray4;
	border-right: none;
}

.infoInput {
	border-color: $gray7;
	background-color: $gray4;
	color: $gray5;

	:focus {
		border-color: $light-blue;
		box-shadow: none;
		color: $black;
	}
}

.link {
	height: 50px;
	background-color: $light-blue;
	text-transform: uppercase;
}

.spinner {
	float: right;
}

.spinnerText {
	display: flex;
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
	text-transform: uppercase;
}

.link {

	:focus,
	:hover,
	:active {
		background-color: $indigo;
	}
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

.btn-primary:not(:disabled):not(.disabled):active {
	background-color: $indigo;
}

.inputRow {
	margin: 10px auto;
}

.inputIcons {
	width: 100%;
	margin-bottom: 10px;
	color: $gray5;

	i {
		position: absolute;
	}

	&.error {
		color: $red;
	}
}

.icon {
	padding: 10px 15px;
	text-align: center;

	&.error {
		color: $red;
	}
}

.inputFieldError:focus {
	outline-color: transparent;
	border: 2px solid $red;
	color: $black;
}

.dotsLoader svg {
	display: block;
	margin: calc(50vh - 100px) auto;
}

.desktopDownloadButton {
	position: absolute;
	bottom: 10px;
	right: 10px;
	text-decoration: none;
	padding: 10px;
	color: white;
	background-color: #1e3768;
	border-radius: 4px;
}

.boxColumn {
	flex-grow: 0;
	max-width: 100%;
	flex-basis: 100%;
	box-sizing: border-box;
	margin: 0;

	@media (min-width: 600px) {
		max-width: 75%;
		flex-basis: 75%;
	}

	@media (min-width: 960px) {
		max-width: 58.333333%;
		flex-basis: 58.333333%;
	}

	@media (min-width: 1280px) {
		max-width: 41.666667%;
		flex-basis: 41.666667%;
	}
}

@media screen and (max-width: 576px) {

	.container,
	.boxColumn {
		padding: 0;
	}

	.App.container {
		height: calc(100vh - 3.25rem);
		width: 100vw;
		max-width: 100vw;
		overflow-x: hidden;
		overflow-y: scroll;
		background-color: $white;
	}

	.box {
		margin: 0;
	}

	header {
		border-radius: 0;
		background-color: $gray4;
	}
}