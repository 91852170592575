@import '../../css/definition.colors.scss';

.input {
	box-sizing: border-box;

	&.invalid {
		border: solid $blisss-red 1px;
	}
}

.wrapper {
	padding-left: 40px;
	display: flex;
	justify-content: space-between;
	position: relative;

	.errorIcon {
		position: absolute;
		left: 0px;
		color: $blisss-red;
	}

	label {
		width: 45%;
		padding: 4px 0;
		box-sizing: border-box;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $gray8;
	}

	.textarea {
		box-sizing: border-box;
		resize: none;
		width: 100%;
		margin: 2px 0;
		font-size: 16px;
		padding: 3px 13px;
		border-radius: 14px;
		min-height: 27px;
	}


	.input {
		border: solid $grey-block-border 1px;

		&.multiSelect {
			margin: 10px;
			width: calc(100% - 20px);
		}

		&:focus-visible {
			outline: none;
			appearance: none;
			background-color: $blue-hover;
			border-color: $blisss-blue;
		}

		&.invalid {
			border: solid $blisss-red 1px;
		}
	}

	input,
	select,
	.hyperlinkWrapper {
		box-sizing: border-box;
		width: 100%;
		margin: 2px 0;
		font-size: 16px;
		padding: 3px 10px;
		border-radius: 14px;
		min-height: 27px;
	}

	.hyperlinkWrapper {
		border: solid transparent 1px;
		padding: 3px 13px;
	}

	a {
		color: $blisss-linkblue;
		text-decoration: none;
	}


	input:read-only,
	textarea:read-only,
	select:disabled {
		border-color: transparent;
		color: black;
		appearance: none;
		outline: none;

		&:hover {
			cursor: default;
		}

		&:focus-visible {
			background-color: transparent;
		}
	}

	textarea,
	select,
	.dateTimeInput {
		font-weight: 500;
		font-size: 16px;
	}

	.weekInput {
		@extend .dateTimeInput;
		display: flex;

		.weekText {
			width: 200px;
			height: 100%;
			margin: 2px 0;
			padding: 3px 10px;
		}
	}

	.inputField {
		display: flex;
		width: 70%;
	}
}

.textRow {
	padding: 3px 13px !important;
}

.boolean {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin: 2px 0;
	min-height: 27px;

	.selection {
		width: 100%;
		border: solid $blisss-blue 1px;
		color: $blisss-blue;
		padding: 3px;
		box-sizing: border-box;
		text-align: center;
		border-radius: 14px; 

		&:first-child {
			margin-right: 6px;
		}

		&.active {
			background-color: $blue-hover;
			font-size: 16px;
			font-weight: 500;
			border: solid $blisss-blue 2px;
			padding: 2px;
		}
	}

	&.notEditing {
		.selection {
			display: none;

			&.active {
				background-color: transparent;
				border: transparent;
				padding: 3px 10px;
				color: black;
				text-align: left;
				display: block;
			}
		}
	}
}

.hidden {
	display: none !important;
}

.dropdownWrapper {
	width: 100%;
	position: relative;

	&.filterDropDown {
		display: flex;
		align-items: center;
		border: 1px solid $gray4;
		border-radius: 6px;
		height: 28px;
		padding: 0 6px;
		margin-top: 10px;
		cursor: pointer;

		&.activeFilter {
			border: 1px solid $blisss-blue;
			background-color: $blisss-blue;

			.filterName {
				color: $white;
			}

			input {
				background-color: $blisss-blue;
				color: $white;
			}

			svg {
				path {
					color: $white;
				}
			}
		}

		&.hasSelected {
			input {
				margin-right: 45px;
			}
		}

		.filterName {
			font-size: 14px;
			font-variation-settings: 'wght' 450, 'wdth' 50;
			color: $gray7;
			text-transform: capitalize;
			white-space: nowrap;
		}

		input {
			border: none;
			height: 100%;
			width: 100%;
			outline: none;
			border-radius: 15px;
			font-size: 14px;
			font-variation-settings: 'wght' 350, 'wdth' 40;
			text-transform: capitalize;
			color: $blisss-blue;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			field-sizing: content;
			margin-right: 23px;
			max-width: 145px;
			cursor: pointer;
		}

		.valuesContainer {
			border: none;
			height: 100%;
			width: 100%;
			outline: none;
			border-radius: 15px;
			font-size: 14px;
			font-variation-settings: 'wght' 350, 'wdth' 40;
			text-transform: capitalize;
			color: #fff;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 23px;
			max-width: 145px;
			cursor: pointer;
			display: flex;
			align-items: center;
		}

		.textFilter {
			display: flex;
			flex-direction: column;

			input {
				padding: 10px;
				width: auto;
			}

			.filterAction {
				display: flex;
				border-top: 1px solid $gray4;

				button {
					margin: 10px;
				}
			}
		}

		.checkboxFilter {
			display: flex;
			align-items: center;

			input {
				width: auto;
			}

			label {
				text-transform: uppercase;
			}

			>div {
				&:hover {
					background-color: $blue-hover;

					p {
						color: $blisss-blue;
					}
				}

				>div {
					white-space: nowrap;

					>div {
						margin-left: 5px;
					}
				}
			}
		}

		.clearFilters {
			border-top: 1px solid $gray4;
			padding: 10px;
			font-size: 14px;
			font-variation-settings: 'wght' 450, 'wdth' 50;
			cursor: pointer;
			color: $gray7;

			&:hover {
				color: $blisss-blue;
			}
		}

		.dropdownBox {
			top: 30px;
			width: auto;
			min-width: 110px;
		}

		.arrowSelectedBlock {
			display: flex;
			top: 6px;

			.numOfSelected {
				color: $white;
				font-size: 14px;
				margin-right: 2px;
			}
		}

		.filterNamesHidden {
			right: 0;
		}

		.valuesContainer {
			border: none;
			height: 100%;
			width: 100%;
			outline: none;
			border-radius: 15px;
			font-size: 14px;
			font-variation-settings: 'wght' 350, 'wdth' 40;
			text-transform: capitalize;
			color: #fff;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 23px;
			max-width: 145px;
			cursor: pointer;
			display: flex;
			align-items: center;
		}

		.valuesSelected {
			margin-right: 45px;
		}

		.filterNamesHidden {
			right: 10px;
			bottom: 6px;
		}
	}

	&.editorDropDown {
		width: 100%;
		margin: 2px 0;
		font-size: 16px;
		padding: 3px 13px;
		border-radius: 14px;
		min-height: 27px;
	}	

	.arrowSelectedBlock {
		position: absolute;
		transform: rotate(90deg);
		top: 4px;
		z-index: 1;
		right: 10px;
		cursor: pointer;
	}

	.valuesSelected {
		margin-right: 45px;
	}
}

.dropdownBox {
	width: 100%;
	position: absolute;
	z-index: 5;
	top: 34px;
	left: 0;
	padding: 6px 0;
	background-color: $white;
	border: solid $grey-outline-border 1px;
	border-radius: 6px;
	box-sizing: border-box;
	margin: 2px 0;

	.closeButton {
		margin-left: auto;
	}

	.topBar {
		display: flex;

		.dropdownSearch {
			border-radius: 6px;
			height: 25px;

			input {
				text-transform: capitalize;
				height: 25px;
				padding: 5px;
				margin: 0;
			}
		}
	}
}

.optionList {
	max-height: 300px;
	overflow-y: auto;
}

.closeButton {
	color: $blisss-blue;
	margin: 3px;
	cursor: pointer;
	transition: .1s color;

	&:hover {
		color: $blisss-orange;
	}
}

.dropdownOption {
	padding: 10px 16px;
	cursor: pointer;

	&.selected {
		background-color: $blue-hover;
		color: $blisss-blue;
	}
}

.InputActionObject {
	position: absolute;
	z-index: 100;
	margin: 4px 10px;
	width: 24px;
	color: $blisss-blue;
	text-align: center;
}

.inputAction {
	width: 72px;
	cursor: pointer;
}

.entityLink {
	cursor: pointer;
	display: inline;
	color: $blisss-linkblue;
	transition: color .1s;

	&:hover {
		color: $blisss-orange
	}
}