@import '../../css/definition.colors.scss';
@import '../../css/definition.dimensions.scss';
@import '../../localization/capitalization.scss';

//This file came from ./Grid/style.module.css
//While redesigning, I need this file globally (SF-3779)
//Part(s) of this file might go back to original location. 
/* TODO: Clean and Fix. Still experimenting... */

.grid {
	display: flex;
	margin: 0 auto;
	column-gap: 20px;
	width: 100%;
	height: 100%;
	max-width: 1600px;
	box-sizing: border-box;

	.column {
		display: flex;
		/* Do not set grow, only basis */
		flex-direction: column;
		/* for Firefox */
		min-width: 0;
		/* min-width prevents overflow, dunno */
		min-height: 0;

		&.fourth {
			flex-basis: 25%;
		}

		&.half {
			flex-basis: 50%;
		}

		&.threeFourths {
			flex-basis: 75%;
		}

		&.whole {
			flex-basis: 100%;
		}
	}

	.tabs {
		flex: 0 0 40px;
		position: relative;
		display: flex;

		.plus {
			display: flex;
			position: absolute;
			right: 1px;
			flex: 0 0 30px;
			font-size: 25px;
			align-items: center;
			height: 100%;
		}

		.container {
			font-size: 20px;
			width: 100%;
			display: flex;


			.side {
				width: 400px;
				text-transform: uppercase;

				.groupTitle {
					color: $gray8;
					border: solid $grey-outline-border;
					border-width: 2px 0;
					padding: 10px 15px;
					background: linear-gradient(90deg, transparent 0%, $grey-block-border 100%);
				}

				.tabIcon {
					margin-right: 25px;
					color: $blisss-darkblue;
				}

				.group {
					padding-bottom: 30px;

					.tabLink {
						padding: 10px 15px;
						border-bottom: solid $grey-outline-border 2px;
						cursor: pointer;
						color: $blisss-blue;
						transition: background-color .02s;

						&:hover {
							background-color: $grey-accent-background;
						}
					}
				}
			}

			.top {
				display: flex;
				justify-content: space-between;

				.tabTops {
					display: inline-block;

					.tabTop {
						display: inline-block;
						padding: 0 10px;
						border: solid transparent 1px;
						justify-content: center;
						text-decoration: none;
						cursor: pointer;

						&:first-child {
							margin-left: 1px;
						}

						&.active {
							padding: 8px 10px 0;
							background-color: #fff;
							height: 30px;
							justify-content: center;
							text-decoration: none;
							border-color: #ccc;
							border-bottom: none;
							border-radius: 5px 5px 0 0;
							margin: 0 1px;
						}

						&.line {
							border-right: solid black 2px;

							&:last-of-type {
								border-right: none;
							}
						}

						&:hover {
							color: $blisss-darkblue;
						}
					}
				}
			}

			.tab {
				width: 100%;
				height: 100%;
			}
		}
	}

	.rightElement {
		display: inline-block;
	}

	.tableRow {
		height: 33px;

		th {
			width: 250px;
			text-align: left;
			color: $gray8;
			font-weight: 100;
			padding-left: 30px;
		}

		td {
			color: $gray9;
			padding: 4px 16px 2px;
		}
	}

	.rowHeader {
		flex: 0 0 40px;
		display: flex;
		justify-content: center;
		/* align horizontal */
		align-items: center;
		/* align vertical */
		overflow: hidden;

		// .icon {
		// 	flex: 0 0 30px;

		// 	&.right {
		// 		flex: 0 0 30px;
		// 		font-size: 25px;
		// 		text-align: right;
		// 	}
		// }

		.text {
			font-variation-settings: 'wght' 500;
			color: $gray9;
			flex-grow: 1;
			font-size: 15px;
			text-align: center;
		}

		.breadcrumb {
			color: $gray9;
			flex-grow: 1;
			font-size: 15px;
			font-variation-settings: 'wght' 500;
			text-align: left;

			a {

				&:link,
				&:visited {
					color: $gray9;
				}

				&:hover {
					color: $blisss-blue;
				}
			}
		}
	}
}

.rowSubHeader {
	flex: 0 0 60px;
	display: flex;
	align-items: center;
	background-color: $white;
	border: 1px solid $gray4;
	box-shadow: 0 7.5px 7.5px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4.5px 10px rgba(0, 0, 0, 0.15);

	.detail {
		flex: 1 1 1000px;
		display: flex;
		height: 100%;
		align-items: center;
	}

	.search,
	.searchBorder {
		flex: 1 1 auto;
		margin-left: 10px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		border-radius: 15px;
		background-color: $grey-accent-background;
		height: 35px;

		.question {
			text-align: center;
			flex: 0 0 40px;
			font-size: 20px;

			i,
			svg {
				color: $gray7;
			}
		}

		.filter {
			text-align: center;
			flex: 0 0 40px;
			font-size: 20px;
		}

		.box {
			flex-grow: 1;

			input {
				background-color: transparent;
				border-width: 0;
				border: none;
				font-size: 15px;
				line-height: 20px;
				padding: 0;
				width: 100%;

				&:focus {
					outline: none;
				}
			}
		}
	}

	.searchBorder {
		border: 1px solid $gray4;
	}

	.detail {
		color: $blisss-darkblue;

		.icon {
			padding-left: 15px;
		}

		.name {
			flex-grow: 1;
			padding-left: 10px;
			padding-right: 10px;
			display: flex;
			align-items: center;
			font-variation-settings: 'wght' 500;

			&.big {
				font-size: 20px;
			}

			&.small {
				font-size: 15px;
			}
		}

		.buttons {
			color: $blisss-blue;
			font-size: 20px;
			display: flex;
			align-items: center;
			padding: 10px;
			text-decoration: none;

			* {
				padding: 10px;
				cursor: pointer;
				transition: color 0.1s;

				&:hover {
					color: $blisss-darkblue;
				}
			}
		}
	}
}

.listIcon {
	flex: 0 0 50px;
	padding-left: 15px;
}

.listTitle {
	font-variation-settings: 'wght' 350;	
	text-transform: capitalize;
	font-size: 30px;
	color: $blisss-darkblue;
}

.listSearchBar {
	flex: 0 1 350px;
}

.rowScroll {
	flex-grow: 1;
	background-color: $white;
	border-radius: 0 0 15px 15px;

	//For now, make an exemption for firefox
	@-moz-document url-prefix() {
		border-radius: 0;
	}

	border: 1px solid $gray4;
	border-top: 0;
	box-shadow: 0px 4.5px 10px rgba(0, 0, 0, 0.15);

	overflow-y: auto;
	min-height: 0;
	/* for Firefox */
	scrollbar-color: $gray5 transparent;
	scrollbar-width: thin;
	margin-bottom: 10px;

	@supports(overflow-y: overlay) {
		overflow-y: overlay;
	}

	&::-webkit-scrollbar {
		width: 8px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: $gray5;
		box-shadow: inset 2px 2px 5px 0 rgba($white, 0.5);

		&:hover {
			background-color: $blisss-orange;
		}
	}
}

.tab {
	background-color: white;
	padding: 10px 8px 6px;
	border: solid $grey-outline-border 1px;
	border-width: 1px 1px 0 1px;
	border-radius: 8px 8px 0 0;
	margin-right: 5px;
	cursor: pointer;
	align-self: flex-end;
	transition: background-color .2s;

	&:hover {
		background-color: $grey-accent-background;
	}
}