@import '../../css/definition.colors.scss';

.rounded {
	font-size: 20px;
	width: 31px;
	height: 31px;
	line-height: 31px;
	background-color: #CCC;
	border-radius: 5px;
	color: #FFF;
	text-align: center;
}

.rounded_dash {
	font-size: 20px;
	width: 31px;
	height: 31px;
	line-height: 31px;
	background-color: $blisss-blue;
	border-radius: 5px;
	color: #FFF;
	text-align: center;
	float: left;
	margin-left: 15px;
	
	svg {
		color: #FFFFFF;
	}
}

.gen {
	font-size: 22px;
	line-height: 30px;
	height: 30px;
	margin-left: 3px;

	svg {
		color: $blisss-darkblue;
	}

}

.list {
	font-size: 20px;
	line-height: 30px;
	text-align: center;

	svg {
		color: $blisss-darkblue;
	}
}

.plus {
	font-size: 24px;
	margin-right: 3px;
	line-height: 30px;
	height: 30px;

	svg {
		color: $blisss-blue;
	}

	&:hover svg  {
		color: $blisss-orange !important;
	}

}