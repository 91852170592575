@import '../../../css/definition.colors.scss';

.container {
	height: calc(100% - 43px);
	display: flex;
	flex-direction: column;
	background-color: white;
	margin-top: 43px;
}

.form {
	flex-grow: 1;
	max-height: calc(100vh - 150px);
	overflow-y: scroll;
}

.header {
	padding: 27px 57.5px 26px 20px;
	height: 30px;
	border: 2px solid $grey-outline-border;
	border-width: 2px 0;
	display: flex;
	justify-content: space-between;
	flex-basis: 20px;
	color: $blisss-darkblue;
	font-variation-settings: "wdth" 65, "wght" 450;

	.icon {
		margin-right: 10px;
		margin-bottom: 5px;
		width: 30px;
	}

	.label {
		font-size: 24px;
		text-transform: capitalize;

		.name {
			display: inline-block;
			max-width: 500px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: bold;
			letter-spacing: 0.6px;
		}
	}
}

.inputHeader {
	text-transform: uppercase;
	margin-left: 10px;
	font-size: 16px;
	font-variation-settings: 'wdth' 120;
}

.formButton {
	margin-right: 15px;
	color: $blisss-blue;
	cursor: pointer;

	&.withDivider {
		padding-right: 15px;
		border-right: solid $grey-block-border 2px;
	}

	&.disabled {
		cursor: default;
		color: $grey-outline-border;
	}

	&:last-child {
		margin-right: 0;
	}
}

.preButtonText {
	margin-right: 15px;
	color: $blisss-blue;
	cursor: pointer;
}