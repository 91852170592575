@import '../../css/definition.colors.scss';

.dropbtn {
	background-color: $blisss-blue;
	color: white;
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: $blisss-darkblue;
	}
}

.dropdown {
	height: 100%;
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
}

.dropdownContent {
	display: block;
	position: fixed;
	background-color: #fff;
	min-width: 160px;
	overflow: auto;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 10;
	margin: 0;
	padding: 8px 0;

	a,
	div,
	li {
		padding-left: 16px;
		padding-right: 16px;
		display: flex;
		width: auto;
		overflow: hidden;
		font-size: 1rem;
		box-sizing: border-box;
		min-height: auto;
		line-height: 1.5;
		padding-top: 6px;
		white-space: nowrap;
		letter-spacing: 0.00938em;
		padding-bottom: 6px;
		justify-content: flex-start;
		align-items: center;

		&:hover {
			background-color: $blue-hover;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 1600px) {
	.dropdownContent {
		right: 8px;
	}
}