@import '../../css/definition.colors.scss';

.button {
	min-width: 40px;
	padding: 9px 20px;
	text-transform: uppercase;
	position: relative;
	background-color: $white;
	border: solid $gray5 1px;
	color: $gray7;
	transition: background-color 0.2s, border-color 0.2s, color 0.2s, padding 0.2s ease-out;
	box-sizing: border-box;
	border-radius: 10px;
	cursor: pointer;
	font-size: 14px;
	font-variation-settings: 'wght' 450, 'wdth' 120;

	&:hover {
		color: $blisss-orange;
		border-color: $blisss-orange;
	}

	&.loading {
		background-color: $white;
		border-color: $gray4;
		color: $gray5;
		padding-left: 10px;
		padding-right: 30px;
	}

	&:disabled {
		border-color: $grey-search-magnifier;
		color: $grey-search-magnifier;
		background-color: $grey-page;
		cursor: default;
	}

	&.borderTheme {
		color: $blisss-blue;
		border-color: $blisss-blue;

		&:hover {
			background-color: $blue-selected;
		}
	}

	&.confirmTheme {
		background-color: $blisss-blue;
		border-color: transparent;
		color: $white;

		&:disabled {
			border-color: $grey-search-magnifier;
			color: $grey-search-magnifier;
			background-color: $grey-page;
			cursor: default;

			&:hover {
				background-color: $grey-page;
			}

			&.loading {
				background-color: $grey-page;
			}
		}

		&:hover {
			background-color: $blisss-orange;
		}

		&.loading {
			background-color: rgba($color: $blisss-blue, $alpha: .5);
		}

		.spinner {
			color: $white;
		}
	}
}

.spinner {
	color: $blisss-blue;
	font-size: 10px;
	position: absolute;
	right: 5px;
	bottom: 7.5px;
	transform: translateZ(0);
	animation: load8 1.1s infinite linear;
	display: inline-block;
}



@keyframes load8 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}