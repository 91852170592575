@import '../../css/definition.colors.scss';

/* TODO: Clean and Fix. Still experimenting... */

.localeMenu {
	color: #27aae1;
	height: 100%;
	background: inherit;
	border: none;
	transition: 0.1s color;
	margin: 0;
	padding-left: 10px;
	padding-right: 10px;

	&:hover {
		color: $blisss-orange;
		cursor: pointer;
	}

	span {
		font-size: 25px;
		line-height: 25px;
		display: flex;
		align-items: center;
		height: 100%;
	}

	.languageSelector {
		display: block;
		margin-left: 3px;

		.language {
			height: 16px;
			line-height: 16px;
			text-transform: uppercase;
			font-size: 16px;
			margin-left: 1px;
		}

		.downIcon {
			display: block;
			font-size: 15px;
			line-height: 14px;
			height: 8px;
		}
	}

	button {
		color: $light-blue;
	}

	.localeMenuItem {
		cursor: default;
	}
}

.wrappedLocaleMenu {
	margin: 0.5em 0;
}

.flag {
	width: 1.5em;
}

@media screen and (max-width: 576px) {
	.localeMenu {
		margin: 0;
	}
}