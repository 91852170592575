@import '../../css/definition.colors.scss';

.button {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 0;
  color: $blisss-blue;
}
.disabledButton {
  cursor: default;
  color: $gray5;
}
.icon {
  margin-right: 5px;
}