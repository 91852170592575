@import '../../css/definition.colors.scss';

/* TODO: Clean and Fix. Still experimenting... */

.logoutButtonContainer {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	background-color: transparent;
	margin: 0;
	padding-left: 10px;
	padding-right: 0px;
}

.logoutButton {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background-color: $blisss-blue;
	color: $white;
	border: none;
	transition: 0.1s;

	&:hover {
		cursor: pointer;
		background-color: $blisss-orange;
	}

	span {
		font-size: 25px;
		display: flex;
		align-items: center;
		height: 100%;
	}

	i,
	svg {
		margin-bottom: 1px;
	}
}

.repList {
	margin: 16px;
	border-collapse: collapse;
	overflow-y: scroll;

	th {
		color: $blisss-darkblue;
		text-transform: uppercase;
		background-color: $blue-hover;
		border: solid $grey-block-border 2px;
		border-width: 2px 0px 2px 2px;
		padding: 5px 10px;
		text-align: left;

		&:first-child {
			border-left: 0;
		}
	}

	.codeCell {
		width: 4em;
	}

	.iconCell {
		width: 1em;
	}

	tr {
		display: table;
		width: 100%;
		table-layout: fixed;
	}

	tbody {
		background-color: $white;
		height: 250px;
		display: block;
		overflow: auto;

		tr {
			border-bottom: solid $grey-block-border 1px;
			cursor: pointer;
			transition: background-color .075s;

			&.selected {
				background-color: $blue-selected;
			}

			td {
				padding: 5px 10px;
				height: 2em;
				color: $grey-search-magnifier;
			}
		}
	}
}

.repListActions {
	border-top: solid rgba(0, 0, 0, 0.25) 1px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	padding: 16px;

	button {
		margin-left: 10px;
	}
}