@import '../../../css/definition.colors.scss';

.annotationEditor {
	background-color: $white;
	font-variation-settings: 'wght' 350, 'wdth' 75;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	padding: 5px 57.5px 5px 0;
	background-color: $grey-accent-background;

	.left {
		color: $blisss-darkblue;
		width: 270px;
		display: flex;
		font-weight: 300;
		text-transform: uppercase;

		.labels {
			align-self: center;
		}
	}

	.middle {
		color: $blisss-blue;
		font-size: 20px;
		font-variation-settings: "wdth" 65, "wght" 450;
	}

	.right {
		width: 200px;
		text-align: right;
	}
}

.listHeader {
	@extend .header;
	background-color: $white;
	border: solid $grey-outline-border 2px;
	border-width: 2px 0;
	padding: 21px 57.5px 20px 20px;
}

.headerText {
	color: $blisss-darkblue;
	font-size: 24px;
	font-variation-settings: "wdth" 65, "wght" 450;
	text-transform: capitalize;

	.headerIcon {
		width: 30px;
		margin-right: 10px;
	}
}

.searchBox {
	margin-left: auto;
	margin-right: 14px;
}

.icon {
	margin-right: 14px;
	color: $blisss-darkblue;

	&:first-child {
		margin-left: 14px;
	}

	&.action {
		cursor: pointer;
		color: $blisss-blue;
	}
}

.contentWrapper {
	border: solid $grey-outline-border 2px;

	&.error {
		border: none;

		textarea {
			border: solid $blisss-red 2px;
			background-color: $red-hover;
		}
	}

	textarea {
		height: inherit;
		width: 100%;
		resize: none;
		appearance: none;
		box-sizing: border-box;
		padding: 14px;
		scrollbar-width: thin;
		font-size: 16px;
		border: none;

		// resize: vertical;
		resize: none;

		&:focus-within {
			outline: none;
			background-color: $blue-hover;
		}

		&:read-only {
			background-color: $white;
		}
	}
}

.annotationEditorList {
	margin-top: 43px;
	background-color: white;
	height: calc(100% - 43px);
	overflow-y: auto;
}