@import '../../../css/definition.colors.scss';

.fileListView {
	margin-top: 43px;
	background-color: white;
	height: calc(100% - 43px);
	overflow-y: auto;
}

.listHeader {
	height: 40px;
	background-color: $white;
	border: solid $grey-outline-border 2px;
	border-width: 2px 0;
	padding: 21px 57.5px 20px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerText {
	color: $blisss-darkblue;
	font-size: 24px;
	font-variation-settings: "wdth" 65, "wght" 450;
	text-transform: capitalize;

	.headerIcon {
		width: 30px;
		margin-right: 10px;
	}
}

.uploadButton {
	position: relative;
	transition: all 0.3s ease-in-out;

	svg {
		color: $blisss-blue;
	}

	input {
		opacity: 0;
		width: 24px;
		position: absolute;
		left: 0;
	}

	input[type=file],
	input[type=file]::-webkit-file-upload-button {
		cursor: pointer;
	}

	&:hover {
		svg {
			color: $blisss-orange;
		}
	}
}

.icon {
	&.action {
		cursor: pointer;
	}
}

.filesWrapper {
	height: 100%;

	&.changeBg {
		background-color: $blue-selected;
	}
}

.contentWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 15px;
	padding: 10px;

	&.noItems {
		display: flex;
		justify-content: center;
	}

	.file {
		display: flex;
		margin-bottom: 10px;
		border: solid $grey-outline-border 1.5px;
		flex-direction: column;
		justify-content: space-between;
		padding: 10px;
		gap: 10px;

		span {
			font-size: 14px;
		}

		.fileIcon {
			svg {
				color: $blisss-blue;
			}
		}

		.fileInfo {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 10px;
			width: 100%;

			svg {
				cursor: pointer;

				&.delete {
					color: $blisss-blue;
				}

				&.download {
					color: $blisss-blue;
				}
			}

			.top {
				display: flex;
				align-items: start;
				justify-content: space-between;

				.fileName {
					width: 200px;
					color: $blisss-darkblue;
					font-variation-settings: 'wght' 450, 'wdth' 65;
					margin: 0;
				}

				svg {
					margin-left: 10px;
				}
			}

			.repName {
				color: $blisss-darkblue;
				font-variation-settings: 'wght' 450, 'wdth' 65;
			}

			.bottom {
				display: flex;
				justify-content: space-between;
				flex-direction: column;

				.uploadInfo {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.actions {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 10px;
					margin-top: 20px;

					svg {
						transition: all 0.3s ease-in-out;

						&:hover {
							color: $blisss-orange;
						}
					}
				}
			}
		}
	}

	.noDataAvailable {
		margin: 20px;
		font-style: italic;
		font-variation-settings: 'GRAD' 100;
	}
}

.fileNameIcon {
	display: flex;
	align-items: center;

	svg {
		color: $blisss-blue;
		margin-right: 10px;
	}
}

.dropDialogContent {
	padding: 16px;
	color: $gray8;
}