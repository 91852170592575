@import '../../../../css/definition.colors.scss';

.mandatory {
	border-radius: 100%;
	background-color: $blisss-red;
	color: white;
	position: absolute;
	font-size: 14px;
	line-height: 20px;
	height: 20px;
	width: 20px;
	text-align: center;
	left: calc(50% + 5px);
}

.tabRegular,
.tabActive,
.tabDone,
.tabFinish {
	margin: 0 auto;
	margin-top: 20px;
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 100%;
	border: 2px solid;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 10px;
	background-color: #FFF;
	border-color: #666;
	color: #666;
}

.tabActive {
	background-color: $blue-selected;
	color: $blisss-blue;
	border-color: $blisss-blue;
}

.tabDone {
	width: 40px;
	height: 40px;
	border: none;

	svg {
		width: 40px;
		height: 40px;
		color: $blisss-blue;
	}

	&:hover {
		color: $blisss-darkblue;
	}
}

.tabText {
	font-size: 14px;
	font-variation-settings: 'wght' 550, 'wdth' 60;
	color: $blisss-darkblue;
	text-align: center;
	text-transform: uppercase;
}

.tabFinish {
	font-size: 20px;
	padding: 1px 0 0 1px;
	height: 34px;
	width: 34px;
}