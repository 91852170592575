.errorPageLayout {
	width: 80vw;
	height: 80vh;
	margin: 5% 10%;
	background: white;
	text-align: center;
	padding: 5%;
	box-sizing: border-box;
	display: grid;
	overflow-y: auto;
}

.logo {
	max-width: 100%;
}