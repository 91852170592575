@import '../../../../css/definition.colors.scss';

.container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footerText {
	margin-left: 10px;
	color: $blisss-darkblue;
	font-variation-settings: 'wght' 450, 'wdth' 120;
}

.buttons {
	margin-right: 10px;
}

button {
	&:last-child {
		margin-right: 0;
	}

	margin-right: 10px;
}