@import '../../css/definition.colors.scss';

.modal {
	position: fixed;
	inset: 0;
	/* inset sets all 4 values (top right bottom left) */
	background-color: rgba(0, 0, 0, 0.15);
	transition: all 0.3s ease-in-out;
	margin-top: 64px;
	z-index: 5;
}

.popup {
	/* display: flex;
	flex: 0 0 600px;
	align-self: flex-start; */
	display: flex;
	position: relative;
	/* needed for aligning close button in child */
	min-height: 700px;
	max-height: 800px;
	max-width: 1200px;
	margin: 0 auto;
	background-color: #F1F1F1;
	border: 1px solid #bfbfbf;
	border-radius: 0 0 15px 15px;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.22);
	flex-direction: column;
}

.content {
	background-color: red;
	width: 880px;
}

.closeButton {
	position: absolute;
	right: -30px;
	top: 16px;
	background-color: $white;
	padding: 5px;
	border-radius: 100%;
	color: $blisss-blue;
	cursor: pointer;
	z-index: 10;

	&:hover {
		color: $blisss-orange;
	}

	@media screen and (max-width: 1250px) {
		right: 5px;
		top: 5px;
	}
}

.pop_menu {
	flex: 0 0 263px;
}

.pop_menu_split {
	display: flex;
	border-right: 1px solid #ccc;
	width: 13px;
	align-items: center;
	justify-content: center;
}

.pop_content {
	display: flex;
	flex: 1 0 auto;
	margin-top: 20px;
	margin-right: 20px;
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid #cccccc;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}