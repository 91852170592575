@import '../../css/definition.colors.scss';

.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.header {
	padding: 20px;
	border-bottom: 1px solid #333;
	display: flex;
	justify-content: space-between;
	flex-basis: 20px;

	.listButtons {
		margin-left: auto;
	}
}

.listButton {
	margin-right: 15px;

	&.withDivider {
		padding-right: 15px;
		border-right: solid $grey-block-border 2px;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		cursor: pointer;
	}
}