@import '../../css/definition.colors.scss';

.wrapper {
	background-color: $grey-outline-border;
	width: 100%;
	height: 5px;
}

.progress {
	background-color: $blisss-blue;
	height: 100%;
	transition: width .3s;
}