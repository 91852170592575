@import '../../css/definition.colors.scss';

.displayNone {
	display: none;
}

.marginAuto {
	margin: auto;
}

.navMenu {
	.setting {
		color: $blisss-darkblue;
		display: flex;
		font-weight: 500;
		font-size: 15px;
		width: 100%;
		min-height: 63px;
		border-bottom: 1px solid $grey-block-border;
		position: relative;
		background-color: transparent;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		text-decoration: none;

		&.selected {
			font-weight: bold;
		}

		&:hover,
		&.selected {
			background-color: $blue-hover;
		}

		a {
			flex: 1 1 auto;
			display: flex;
		}

		.title {
			display: flex;
			margin-left: 15px;
			margin-right: 12px;
			flex: 1;
		}

		.icon {
			flex: 0 0 31px;
			font-size: 20px;
			width: 31px;
			height: 31px;
			line-height: 31px;
			// background-color: $gray4;
			border-radius: 5px;
			color: $white;
			text-align: center;
			margin-left: 15px;

			i,
			svg {
				color: $white;
				opacity: 0.9;
			}
		}

		// a {
		//     text-decoration: none;
		//     i {
		//         color: $white;
		//         &:hover {
		//             color: $white;
		//         }
		//     }
		// }
	}
}

.extensionTypeTitle {
	margin: 0;
	border-bottom: solid black 1px;
	padding: 0.3em;
	background-color: rgba(0, 0, 0, 0.01);
	cursor: pointer;
}

.groupDropZoneWrapper {
	margin: 0.4em;
	border-top: solid transparent 2px;
	transition: border-color 0.2s;

	&.borderTop {
		border-color: $blisss-darkblue;
	}
}

.groupDropZone {
	transition: 0.05s background-color;
	min-height: 4em;
	margin: 0.4em;
	padding: 0.8em;
	border-radius: 0.25em;

	&.dragging {
		&.dragOver {
			background-color: $blue-hover;
		}
	}

	&.empty {
		border: dashed $blisss-blue 2px;
	}

	&.filled {
		border: solid $blisss-blue 1px;
	}

	.description {
		text-align: center;
		color: $blisss-blue;
		margin-top: 0.5em;
		font-size: 1.3em;
	}
}

.groupLabel {
	display: flex;
	cursor: pointer;
	color: $blisss-darkblue;

	.grip {
		font-size: 1.25em;
		width: 1em;
		margin-left: auto;
		padding: 0.25em 0.25em 0.4em;
		text-align: center;
		cursor: move;
	}

	h5 {
		margin: 1em;
	}
}

.dropZone {
	padding: 0 0.8em;
	min-height: 20px;

	.indicator {
		margin: 0.4em 0;
		border-bottom: solid transparent 2px;
		transition: border-color 0.2s;
	}

	&.dragging {
		&.dragOver {
			.indicator {
				border-color: $blisss-darkblue;
			}
		}
	}
}

.typeSelection {
	padding: 0.5em 0.5em 1em;
	margin: 0.3em;
	cursor: pointer;
	background-color: rgba(238, 153, 68, 0.2);
	border: solid rgb(238, 153, 68) 1px;
}

.detailProperty {
	border-bottom: solid black 1px;

	input {
		border: solid blue 1px;
	}

	&:first-child {
		border-top: solid black 1px;
	}

	label {
		display: inline-block;
		width: 8em;
		padding: 0.2em 1em;
		box-sizing: border-box;
		border-right: solid black 1px;
	}

	input {
		min-width: 14em;

		&.invalid {
			background-color: rgba(255, 0, 0, 0.2);
		}
	}

	select {
		&.invalid {
			background-color: rgba(255, 0, 0, 0.2);
		}
	}
}

.button {
	background-color: $blisss-blue;
	border: none;
	border-radius: 0.5em;
	color: $white;
	padding: 0.2em 0.8em;
	//margin: 1em;
	transition: background-color 0.5s;

	&:hover {
		cursor: pointer;
		// background-color: $blisss-slightlyDarkblue;
	}
}

.componentWrapper {
	margin: 15px;
}

.component {
	display: flex;
	border: 1px solid $grey-block-border;
	background-color: $grey-accent-background;
	border-radius: 15px;
	height: 40px;
	margin: 0 auto;
	color: $gray7;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&.listItem {
		max-width: 235px;
	}

	&.invalid {
		border-color: $blisss-red;
		color: $blisss-red;
	}

	.icon {
		display: flex;
		flex: 0 0 40px;
		justify-content: center;

		i {
			font-size: 20px;
			color: $dark-indigo;
		}
	}

	.text {
		flex: 1 1 auto;
		padding-left: 10px;
		font-size: 15px;
		font-weight: bold;
	}

	.grip {
		display: flex;
		flex: 0 0 40px;
		justify-content: center;
		cursor: move;
	}
}

.component_index {
	display: flex;
	font-size: 15px;
	color: #727272;
	height: 100%;
	flex: 0 0 30px;
	border-left: 1px solid $grey-block-border;
	border-right: 1px solid $grey-block-border;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.component_desc {
	flex: 1 1 auto;
	font-size: 15px;
	color: #000;
}

.line {
	display: block;
	height: 1px;
	line-height: 1px;
}

.componentDetail {
	font-weight: 100;
	display: flex;
	border-bottom: 1px solid #ccc;
	font-size: 14px;
	line-height: 26px;

	&:last-child {
		border-bottom: none;
	}

	.name {
		/* TODO: removed fnt, can't test because of crash */
		padding-left: 20px;
		flex: 0 1 100px;
		border-right: 1px solid #ccc;
		color: #888888;
	}

	.value {
		/* TODO: removed fnt, can't test because of crash */
		padding: 0 20px;
		flex: 1 0 auto;

		input {
			border: none;
			width: 100%;
			outline: none;
			padding-bottom: 2px;

			&:focus {
				border-bottom: solid $blisss-blue 1px;
			}

			&.invalid {
				background-color: rgba(255, 0, 0, 0.2);
				border: solid red 1px;
				border-radius: 3px;
			}
		}

		select {
			&.invalid {
				background-color: rgba(255, 0, 0, 0.2);
				border: solid red 1px;
			}
		}
	}
}

.listValue {
	display: flex;
	border-bottom: 1px solid #ccc;

	&:last-child {
		border-bottom: none;
	}

	input {
		&.invalid {
			background-color: rgba(255, 0, 0, 0.2);
			border: solid red 1px;
			border-radius: 3px;
		}

		margin: 4px 20px;
		border: none;
		flex: 1 0 auto;
	}

	.actions {
		color: $blisss-darkblue;
		font-size: 20px;
		align-self: flex-end;
		text-align: left;
		padding: 0 4px;
		display: flex;
		align-items: center;
		width: 50px;
		justify-content: space-between;

		.move {
			display: flex;
			flex-direction: column;
			height: 40px;
			justify-content: center;
		}

		.icon {
			transition: color 0.1s;

			&:hover {
				color: $blisss-blue;
				cursor: pointer;
			}
		}
	}
}

.spacer {
	display: block;
	min-height: 20px;
}