@import '../../css/definition.colors.scss';

.input {
	box-sizing: border-box;

	&:invalid {
		border: solid $blisss-orange 2px;
		padding-right: 20px;
		background: url('https://upload.wikimedia.org/wikipedia/commons/1/17/Warning.svg') no-repeat right;
		background-size: contain;
		background-position: right;
	}
}

.wrapper {
	padding-left: 40px;
	display: flex;
	justify-content: space-between;
	position: relative;

	label {
		width: 30%;
	}

	.textarea {
		box-sizing: border-box;
		resize: none;
	}

	input,
	textarea,
	select {
		border: solid $grey-block-border 2px;

		&.multiSelect {
			position: absolute;
			z-index: 1;
			top: 24px
		}

		&:focus-visible {
			outline: none;
			appearance: none;
			background-color: $blue-hover;
			border-color: $blisss-blue;
		}
	}

	.hyperlinkWrapper {
		border: solid transparent 2px;
	}

	input,
	textarea,
	select,
	.hyperlinkWrapper {
		width: 100%;
		margin: 6px;
		font-size: 20px;
		padding: 3px 10px;
		border-radius: 12px;
		transition: all .2s;
	}


	input:read-only,
	textarea:read-only,
	select:disabled {
		border-color: transparent;
		color: black;
		appearance: none;
		outline: none;

		:hover {
			cursor: default
		}

		&:focus-visible {
			background-color: transparent;
		}
	}

	textarea,
	select,
	.dateTimeInput {
		font-weight: 500;
		font-size: 20px;
	}

	.inputField {
		display: flex;
		width: 70%;

		.InputActionObject {
			margin: 3px;
			width: 24px;
			color: $blisss-blue
		}
	}
}

.boolean {
	display: flex;
	justify-content: flex-end;
	width: 100%;

	.selection {
		width: 100%;
		border: solid $blisss-blue 1px;
		color: $blisss-blue;
		padding: 3px;
		box-sizing: border-box;
		margin: 6px;
		text-align: center;
		border-radius: 12px;

		&.active {
			border-width: 2px;
			background-color: $blue-hover;
			font-size: 20px;
			font-weight: 500;
		}
	}

	&.notEditing {
		.selection {
			display: none;

			&.active {
				background-color: transparent;
				border-color: transparent;
				padding: 3px 10px;
				color: black;
				text-align: left;
				display: block;
			}
		}
	}
}