@import '../../css/definition.colors.scss';

.modal {
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.15);
	transition: all 0.3s ease-in-out;
	margin-top: 64px;
	z-index: 5;
}

.popup {
	display: flex;
	position: relative;
	/* needed for aligning close button in child */
	height: 700px;
	max-height: calc(100vh - 80px);
	width: 100vw;
	max-width: 1200px;
	margin: 0 auto;
	background-color: $grey-page;
	border: 1px solid $grey-outline-border;
	border-radius: 0 0 15px 15px;
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.22);
	flex-direction: row;
}

.tabList {
	flex-basis: 200px;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 13px;
	overflow-y: scroll;
	scrollbar-width: none;
	
	.tab {
		font-variation-settings: 'wght' 400;
		padding: 10px;
		border-bottom: 1px solid #333;
		display: flex;
		text-transform: uppercase;

		&.clickable {
			padding: 10px 15px;
			border-bottom: solid $grey-outline-border 2px;
			cursor: pointer;
			color: $blisss-blue;
			transition: background-color .02s;

			&:not(.disabled) {
				&:hover {
					background-color: $grey-accent-background;
				}

				&.selected {
					background-color: $grey-accent-background;
				}
			}


			&.disabled {
				background-color: $grey-block-border;
				color: $grey-search-magnifier;
				cursor: not-allowed;
			}
		}

		&.tabHeader {
			font-variation-settings: 'wght' 550;
			color: $grey-search-magnifier;
			border: solid $grey-outline-border;
			border-width: 2px 0;
			padding: 10px 15px;
			margin-top: 30px;
			background: linear-gradient(90deg, transparent 0%, $grey-block-border 100%);
		}
	}
}

.tabIcon {
	color: $blisss-darkblue;
	width: 24px;
	margin-right: 16px;
	text-align: center;
}

.entityView {
	flex-grow: 1;
	max-width: 1000px;
}

.closeButton {
	position: absolute;
	right: -30px;
	top: 16px;
	background-color: $white;
	padding: 5px;
	border-radius: 100%;
	color: $blisss-blue;
	cursor: pointer;

	&:hover {
		color: $blisss-orange;
	}

	@media screen and (max-width: 1250px) {
		right: 5px;
		top: 5px;
	}
}