@import '../../css/definition.colors.scss';

.background {
	top: auto;
	bottom: 0;
	height: calc(100vh - 64px);
}

.popupPanel {
	position: absolute;
	top: 0;
	box-sizing: border-box;
	max-height: calc(100vh - 64px);
	min-width: 1200px;
	margin: 0 auto;
	background-color: $grey-page;
	border: solid $grey-block-border 1px;
	padding: 18px 18px 8px 0;
	border-radius: 0 0 15px 15px;
	box-shadow: 0px 3px 6px #0000001a;
}

.closeButton {
	position: absolute;
	right: -25px;
	top: 40px;
	background-color: $grey-page;
	padding: 2px;
	border-radius: 100%;
	color: $blisss-blue;
	cursor: pointer;

	&:hover {
		color: $blisss-darkblue;
	}



}

@media screen and (max-width: 1200px) {
	.popupForm {
		background-color: hotpink;
	}

	.closeButton {
		right: 5px;
		top: 5px;
	}
}

.popupHeader {
	padding: 14px 14px 0;
	display: flex;
	align-items: center;
	width: 100%;

	.title {
		margin-left: 14px;
		flex: 80%;
		color: $blisss-darkblue;
	}

	.actions {
		display: flex;

		&.extra {
			padding-right: 20px;
			border-right: solid $grey-block-border 2px;
			height: 100%;
		}

		.action {
			margin-left: 20px;

			&.extra {
				padding-right: 20px;
				border-right: solid $grey-block-border 2px;
			}
		}
	}
}

.popupForm {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 100px);
	max-height: 750px;
}

.popupPanelTable {
	width: 100%;
	padding: 1em 0;
	border-spacing: 16px 6px;
}

.inputComponent {
	font-size: 16px;
	border: solid $gray4 1px;
	border-radius: 10px;
	min-height: 25px;
	padding: 3px 15px 1px;
	margin: -4px 0 -2px -16px;
	outline: none;

	&:valid {
		border-color: $blisss-blue;
	}

	&:invalid {
		border-color: $blisss-orange;

		&:required {
			border-color: $blisss-red;
		}
	}
}

select.inputComponent {
	padding-left: 11px;
}

.action {
	color: $blisss-blue;
	cursor: pointer;

	&:hover {
		color: $blisss-darkblue;
	}

	&.inactive {
		color: $grey-search-magnifier !important;
		cursor: default;
	}
}