@import '../../css/definition.colors.scss';

.infoGroup {
	display: flex;
	flex-direction: column;
	border-bottom: solid $grey-block-border 1px;

	.infoGroup {
		border-bottom: none;
	}

	.content {
		&.padded {
			padding: 10px 20px;
		}
	}
}

.infoHeader {
	flex: 1;
	display: flex;
	cursor: pointer;

	&.up {
		border-bottom: solid $grey-block-border 1px;
	}

	&.bgGray {
		background-color: $grey-accent-background;
	}

	.title {
		color: $blisss-darkblue;
		font-weight: 900;
		text-transform: uppercase;
		flex: 1 0 auto;
		padding-left: 20px;
		line-height: 28px;
		letter-spacing: 0.4px;
	}

	.button {
		flex: 0 0 40px;
		color: $blisss-blue;
		font-size: 20px;
		line-height: 28px;
		margin-right: 15px;
	}
}

.infoRow {
	flex: 1;
	display: flex;
	line-height: 30px;

	.title {
		font-style: italic;
		flex: 0 0 200px;
		padding-left: 20px;
		text-align: right;
	}

	.desc {
		padding-left: 30px;
		flex: 1 1 auto;
		text-overflow: ellipsis;
	}

	a {

		&:link,
		&:visited {
			color: $blisss-blue;
			text-decoration: none;
		}

		&:hover {
			color: $blisss-orange;
			text-decoration: underline;
		}
	}
}

.infoMore

/* TODO */
	{
	flex: 0 1 40px;
}