@import '../../css/definition.colors.scss';

.noDataAvailable {
	display: block;
	text-align: center;
	margin: 20px;
	font-style: italic;
	font-variation-settings: 'GRAD' 100;
}

.scrollView {
	display: block;
	overflow-x: auto;
	overflow-y: auto;
	min-width: 100%;

	&.dragging {
		cursor: col-resize;
	}
}

.table {
	border-collapse: collapse;
	border-width: 0;
	border-spacing: 0;
	color: #000; //TODO: COLOR
	font-size: 14px;
	table-layout: auto;

	th,
	td {
		position: relative;
		line-height: 33px;
		font-weight: normal;
		text-align: left;
		padding-left: 15px;
		padding-right: 25px;
	}

	th {
		font-variation-settings: 'wght' 450, 'wdth' 30;
		color: $blisss-darkblue;
		background-color: $gray1;
		border-top: 1px solid $white;
		border-bottom: 1px solid $grey-block-border;
		text-transform: uppercase;
		position: sticky;
		z-index: 1;
		top: 0px;
		line-height: 28px;
		box-sizing: border-box;
		transition: background-color .15s;

		&:not(:first-child) {
			border-left: 1px solid $white;
		}

		&:not(:last-child) {
			border-right: 1px solid $grey-block-border;
		}

		&.clickable {
			cursor: pointer;
			user-select: none;
		}

		&.ordered {
			background-color: $blue-selected;
		}

	}

	td {
		font-variation-settings: 'wght' 400, 'wdth' 90;
		overflow: hidden;
		white-space: nowrap;
		// font-family: Roboto;
		font-size: 14px;
	}

	tr:first-child th {
		box-shadow: 15px 0px 25px rgba(0, 0, 0, 0.15);
	}

	tr:nth-child(odd) {
		background-color: #FBFBFB;
	}

	tr:nth-child(even) {
		background-color: $white;
	}


	.row {
		border-top: 1px solid $gray2;

		&:hover {
			cursor: pointer;
			background-color: $blue-hover;
		}

		&.emphasis {
			border-color: $red-outline-border;
			background-color: $red-page;

			&:hover {
				background-color: $red-hover;
			}
		}
	}

	.cell {
		min-width: 100%;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	.link {
		display: block;
		height: 100%;
		color: $blisss-blue;
	}

	.link:any-link {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.link:hover {
		color: $blisss-orange;
	}

	.main {
		font-variation-settings: 'wght' 450, 'wdth' 50;
		color: $blisss-darkblue;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}
}

.sortIcon {
	position: absolute;
	right: 10px;
	top: 8px;
	cursor: pointer;
}

.resizer {
	position: absolute;
	top: 0;
	right: 0;
	width: 4px;
	height: 100%;
	cursor: col-resize;
	user-select: none;
	background-color: $blisss-darkblue;
}