@import './../../css/definition.colors.scss';
@import './../../css/definition.dimensions.scss';
@import './../../localization/capitalization.scss';

.entity {
	font-size: 15px;
	width: 100%;
	height: 68px;
	border-bottom: 1px solid $grey-block-border;
	padding-top: 9px;
	position: relative;
	background-color: transparent;
	cursor: pointer;
}

.entity:hover {
	background-color: $blue-hover;
}

.entity_icon {
	position: absolute;
	vertical-align: middle;
	left: 15px;
	top: 12px;
	font-size: 20px;
	width: 31px;
	height: 31px;
	line-height: 31px;
	background-color: #ccc;
	border-radius: 5px;
	color: #fff;
	text-align: center;
}

.entity_icon i {
	opacity: 0.9;
}

.entity_title,
.entity_desc {
	display: flex;
	margin-left: 60px;
	margin-right: 12px;
	flex: 1;
}

.entity_title {
	line-height: 18px;
}

.entity_rest {
	color: #727272;
	font-variation-settings: 'wght' 350, 'wdth' 50;
	font-size: 16px;
	display: flex;
	margin-right: 12px;
	margin-top: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.entity_rest div {
	white-space: inherit;
	overflow: inherit;
	text-overflow: inherit;
}

.entity_title_l {
	height: 18px;
	font-variation-settings: 'wght' 600, 'wdth' 75;
	color: $blisss-darkblue;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.entity_title_r {
	flex: 1 0 auto;
	text-align: right;
	font-weight: 500;
	color: black;
	margin-left: 10px;
}

.entity_desc_l {
	color: $blisss-darkblue;
	font-variation-settings: 'wght' 400, 'wdth' 75;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-top: 2px;
}

.entity_desc_r {
	flex: 1 0 auto;
	text-align: right;
	color: black;
	margin-left: 10px;
	padding-top: 2px;
}

.entity_rest_l {
	flex: 0 0 60px;
	text-align: center;
}

.entity_rest_c {}

.entity_rest_r {
	flex: 1 0 auto;
	text-align: right;
	color: $blisss-darkblue;
	margin-left: 10px;
}

.icon {
	float: left;
	margin-left: 15px;
}