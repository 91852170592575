@import '../../css/definition.colors.scss';

.search {
	flex: 1 1 auto;
	margin-left: 10px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	border-radius: 15px;
	background-color: $grey-accent-background;
	height: 35px;

	.question {
		text-align: center;
		flex: 0 0 40px;
		font-size: 20px;

		i,
		svg {
			color: $gray7;
		}
	}

	.filter {
		text-align: center;
		flex: 0 0 40px;
		font-size: 20px;
	}

	.box {
		flex-grow: 1;

		input {
			background-color: transparent;
			border-width: 0;
			border: none;
			font-size: 15px;
			line-height: 20px;
			padding: 0;
			width: 100%;

			&:focus {
				outline: none;
			}
		}
	}
}

.border {
	border: 1px solid $gray4;
	background-color: $white;
}

.nospin {
	transform: none;
	animation: none;
}

.spin {
	transform: translateZ(0);
	animation: load8 1.1s infinite linear;
}

//TODO: move globally (in use elsewhere)
@keyframes load8 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}