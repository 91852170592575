@import '../../../css/definition.colors.scss';

.boundListView {
	background-color: $white;
	height: calc(100% - 64px);
	border-radius: 8px;
	margin: 15px 5px 0 5px;
	box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.filterButtonsRow {
	height: 33px;
	padding: 10px 24px 0;
	display: flex;
	align-content: end;
}

.filterButton {
	text-transform: uppercase;
	padding: 0 15px;
	margin: 10px 0 6px;
	border-right: solid $blisss-darkblue 2px;
	border-left: solid transparent 2px;
	cursor: pointer;
	font-variation-settings: 'wght' 400;

	&.beforeActive {
		border-right: solid transparent 2px;
	}

	&.active {
		border: solid $grey-block-border 2px;
		border-width: 2px 2px 0;
		border-radius: 8px 8px 0 0;
		background-color: $white;
		padding: 8px 15px 0;
		margin: 0;

	}

	&:last-child {
		border-right: none;
	}

	&:has(.active) {
		border-right: none;
	}
}

.topBar {
	padding: 26px 57.5px 25px 20px;
	background-color: $white;
	display: flex;
    flex-direction: column;
	border-bottom: solid $grey-outline-border 2px;
	border-radius: 8px 8px 0 0;
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .bottom {
            display: flex;
            align-items: center;
            column-gap: 20px;
            flex-wrap: wrap;

            > div {
                width: auto;
            }
        }
}

.headerText {
	color: $blisss-darkblue;
	font-size: 24px;
	font-variation-settings: "wdth" 65, "wght" 450;
	text-transform: capitalize;
    display: flex;
    align-items: center;
    
	.icon {
		width: 30px;
		margin-right: 10px;
		margin-bottom: 5px;
	}

	.label {
		font-size: 24px;
		text-transform: capitalize;

		.name {
			display: inline-block;
			max-width: 500px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: bold;
			letter-spacing: 0.6px;
		}
	}
    .typeFilter {
        margin-left: 10px;
    }
}

.searchBox {
	margin-left: auto;
}

.addButton {
	margin: 0 14px;
	cursor: pointer;
	color: $blisss-blue;

	&:hover {
		color: $blisss-orange;
	}
}

.tableContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	height: calc(100% - 130px);
	max-height: calc(100vh - 270px);

	// div:first-child {
	// 	overflow-x: hidden;
	// }

	// table:first-child {
	// 	width: 905px !important;
	// }

	// td {
	// 	div {
	// 		overflow-x: visible !important;
	// 	}
	// }

	th {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 20px;
	}
}

.moreRecordsButton {
	margin-left: auto;
	margin: 16px 0;
}