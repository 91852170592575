@import '../../css/definition.colors.scss';

.backgroundFadeContainer {
	background-color: rgba(0, 0, 0, 0.25);
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dialog {
	min-height: 180px;
	max-height: 500px;
	width: 500px;
	background-color: $grey-page;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 5px;
	box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.25);

	.content {
		padding: 16px;
		display: flex;
		color: $gray8;

		.iconRow {
			margin-right: 25px;
		}

		.textRow {
			width: 100%;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		font-size: 17px;
		font-weight: 600;
		padding: 16px;
		padding-bottom: 10px;
		margin-bottom: 5px;
		color: $black;

		&.underlined {
			border-bottom: solid rgba(0, 0, 0, 0.25) 1px;
		}

		.dismissButton {
			color: $blisss-blue;
			transition: color .2s;

			&:hover {
				color: $blisss-orange;
				cursor: pointer;
			}
		}
	}

	.actions {
		border-top: solid rgba(0, 0, 0, 0.25) 1px;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		padding: 13px 16px;

		button {
			margin-left: 10px;
		}
	}
}